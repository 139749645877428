import { useEffect, useReducer, useRef } from 'react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import { Select } from 'src/components/Public/Form/_components/Select';
import pipe from 'src/lib/helpers/pipe';
import { ResourceItem, TableHeader, ToggleButton } from './components';
import {
  FILTER,
  NewsAndResourcesProps,
  Reducer,
  ReducerState,
  ResourcesProps,
  SORT,
  TransformFunction,
} from './types';

const transformResources = ({ resources, filterBy, sortBy, sliceBy }: ResourcesProps) => {
  const filter: TransformFunction = arr => {
    if (filterBy === 'All') {
      return arr;
    }
    return arr.filter(item => item.type === filterBy);
  };

  let fullLength = resources.length;

  const slice: TransformFunction = arr => {
    // length after being filtered
    fullLength = arr.length;

    if (!sliceBy) {
      return arr;
    }

    return arr.slice(0, sliceBy);
  };

  const sort: TransformFunction = arr => {
    const { direction, property } = sortBy;

    switch (direction) {
      case SORT.ASCEND:
        return arr.sort((a, b) => (a[property] < b[property] ? -1 : 1));
      case SORT.DESCEND:
        return arr.sort((a, b) => (a[property] > b[property] ? -1 : 1));
      default:
        return arr;
    }
  };

  // 1. filter items based on <select>
  // 2. sort items by selected property in ascending or descending order
  // 3. slice out first 5 items if table is not expanded
  const transformedResources = pipe(filter, sort, slice)(resources);

  return { transformedResources, fullLength };
};

// Amount of items not hidden by Show More toggle functionality
const nonHiddenItemsAmount = 5;

const initialState: ReducerState = {
  filterBy: 'All',
  sliceBy: nonHiddenItemsAmount,
  sortBy: { property: 'date', direction: SORT.DESCEND },
  isExpanded: false,
};

const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'filterBy': {
      const { filterBy } = action.payload;
      return { ...state, filterBy };
    }
    case 'sortBy': {
      const { property } = action.payload;
      const toggle = () => (state.sortBy.direction === SORT.ASCEND ? SORT.DESCEND : SORT.ASCEND);
      const shouldToggle = state.sortBy.property === property;
      const direction = shouldToggle ? toggle() : SORT.ASCEND;
      return {
        ...state,
        sortBy: { property, direction },
      };
    }
    case 'toggle': {
      const isExpanded = !state.isExpanded;
      const sliceBy = isExpanded ? null : nonHiddenItemsAmount;

      return { ...state, isExpanded, sliceBy };
    }
    default:
      return initialState;
  }
};

const NewsAndResources = ({ resources, resourceTypes, track, ...rest }: NewsAndResourcesProps) => {
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { transformedResources, fullLength } = transformResources({
    resources,
    ...state,
  });

  const { direction, property } = state.sortBy;

  const isShowToggle = fullLength > nonHiddenItemsAmount;

  useEffect(() => {
    if (state.isExpanded) bodyRef.current?.querySelectorAll('a')[nonHiddenItemsAmount].focus();
  }, [state.isExpanded]);

  const onChange = ({ value }: { value: string }) => {
    const filterBy = value;
    track({ label: 'filter', action: filterBy });
    dispatch({ type: 'filterBy', payload: { filterBy } });
  };

  const onToggle = () => {
    track({ label: 'expansion', action: state.isExpanded ? 'Show Less' : 'Show More' });
    dispatch({ type: 'toggle' });
  };

  return (
    <CallToAction {...rest}>
      <div className="container-4xl">
        <div className="flex bg-gray-lighter md:justify-start items-center px-16 pb-24 pt-32">
          <div className="-mb-48">
            <Select
              error={{ hasError: false }}
              items={resourceTypes}
              label="Show"
              name="show-select"
              {...{
                onChange,
                defaultValue: 'All',
              }}
            />
          </div>
        </div>
        <table className="table table-auto text-left w-full leading-md">
          <caption>
            News and Resources{' '}
            <span role="status">
              sorted by {property} {direction}{' '}
            </span>
          </caption>
          <thead className="border-b border-gray-light">
            <tr>
              {FILTER.map((property, index) => (
                <TableHeader
                  aria-sort={state.sortBy.direction}
                  isActive={state.sortBy.property === property}
                  key={index}
                  onClick={() => dispatch({ type: 'sortBy', payload: { property } })}
                  rotateArrow={state.sortBy.direction === SORT.DESCEND}
                  width={index === 0 ? '' : 'w-1/7'}
                >
                  {property}
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tfoot />
          <tbody ref={bodyRef}>
            {transformedResources.map(item => (
              <ResourceItem {...item} key={item.id} />
            ))}
          </tbody>
        </table>
        {isShowToggle && <ToggleButton onClick={onToggle} isExpanded={state.isExpanded} />}
      </div>
    </CallToAction>
  );
};

export default NewsAndResources;
