/* eslint-disable complexity */
import SecondaryNav from './index';
import Composition from 'src/lib/Composition';
import { useSitecoreContext } from 'src/lib/SitecoreContextFactory';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import { SecondaryNavType } from './types';

const { compositionFunction, component } = Composition(SecondaryNav)(() => {
  const { status } = useAppContext();
  const items: SecondaryNavType['items'] = status?.page?.navItems?.items;

  const { context: sitecoreContext } = useSitecoreContext();
  const suppressNav = sitecoreContext?.route?.fields?.['Suppress Navigation']?.value;

  return { items, suppressNav };
});

export { compositionFunction };
export default component;
