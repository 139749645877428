import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/Composition';
import { Data } from './data';
import LatestNews from './index';

const { compositionFunction, component } = Composition(LatestNews)<typeof Data>(
  ({ fields, rendering }) => {
    const selectedCategories = fields?.datasource?.['News Categories']?.map(
      ({ fields }) => fields?.Value?.value
    );

    const news = fields?.categoryNewsList?.map(item => ({
      analytics: {
        action: `${item.title}-|-${item.link}`,
        category: 'latest_news',
        label: fields?.datasource?.Headline?.value,
        guid: rendering?.uid,
      },
      image: { value: { alt: item.altText, src: item.imageURL } },
      link: item.link,
      date: item.pubDate,
      title: { value: item.title },
      category: item.categories
        ?.filter(itemCategory =>
          selectedCategories.some(selectedCategory => selectedCategory === itemCategory)
        )
        ?.join(', '),
    }));

    return {
      ...CallToActionComposition({ fields: fields?.datasource, rendering }),
      items: news,
    };
  }
);

export { compositionFunction, component as default };
