import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition, { JssObject } from 'src/lib/Composition';
import ProductCards from './index';
import { ProductCardType } from './types';

const { compositionFunction, component } = Composition(ProductCards)(({ fields }) => {
  const regularCards = fields?.['Product Cards'];
  const featuredCard = fields?.['Feature Card'];
  const itemsLimit = featuredCard?.fields ? 4 : 3;
  const CTA = CallToActionComposition({ fields });

  const parseCardData = ({ isFeatured = false, fields, id }: JssObject) =>
    fields && {
      analytics: {
        category: `${isFeatured ? 'product_card_featured' : 'product_card_non-featured'}`,
        label: fields?.Title?.value,
        action: [fields?.CTA?.value?.text, fields?.CTA?.value?.href].filter(Boolean).join(' | '),
        guid: id,
        event: 'event-click',
      },
      body: fields?.Body,
      category: fields?.Category,
      cta: fields?.CTA,
      image: fields?.Image,
      isFeatured,
      title: fields?.Title,
    };

  const parsedRegularCards = regularCards.reduce(
    (acc: ProductCardType['items'], curr: JssObject) => {
      return [...acc, parseCardData(curr)];
    },
    []
  );

  // we have to have more than 1 regular card before we show them
  const getRegularCards = (parsedRegularCards.length > 1 && parsedRegularCards) || [];

  /**
   * Possible Display Variations
   * 1 featured card, with 3 regular cards
   * 1 featured card, with 2 regular cards
   * 1 featured card, no regular cards
   * 3 regular cards
   * 2 regular cards
   *
   * An array containing either a featured card first followed by regular cards or
   * just an array of regular cards. It filters out any undefined values from
   * parseCardData and then makes sure that only the max limit can pass through to the component
   * max limit: w/featured card - 4 items, w/o - 3 items
   */
  const items = [
    parseCardData({ ...featuredCard, isFeatured: true }),
    ...(regularCards && getRegularCards),
  ]
    .filter(Boolean)
    .splice(0, itemsLimit);

  return {
    ...CTA,
    items,
  };
});

export { compositionFunction, component as default };
