import Compostion from 'src/lib/Composition';
import { maxFlat } from 'src/lib/helpers';
import NavTree from './index';
import { useAppContext } from 'src/lib/Contexts/AppContext';

const { component, compositionFunction } = Compostion(NavTree)(({ fields }) => {
  const { status } = useAppContext();
  const selectedJurisdiction = status.jurisdiction?.jurisdictionCode;

  return {
    items: maxFlat(3, { selectedJurisdiction })(fields?.items),
    navAriaLabel: fields?.navAriaLabel?.value,
  };
});

export { compositionFunction };
export default component;
