import Composition, { JssObject } from 'src/lib/Composition';
import PrimaryNav from './index';

const getAccountText = (fields?: JssObject): JSS.TextField => {
  if (fields?.accountText?.value) {
    return fields.accountText;
  }
  return { value: 'Profile' };
};

const getProfileLinks = (fields?: JssObject): { href?: string; text?: string }[] => {
  const signOut = { href: fields?.signOutLink?.value, text: fields?.signOutLabel?.value };
  const dashboard = { href: fields?.dashboardLink?.value, text: fields?.dashboardText?.value };
  const profileLinks = [dashboard, signOut];
  return profileLinks;
};

const { compositionFunction, component } = Composition(PrimaryNav)(({ fields, rendering }) => {
  const auth = fields?.isAuthenticated;
  const multiExp = fields?.isMultiExperience;

  const isInMaintenance = () =>
    fields?.maintenanceWindowFallsWithinTimeSpan && fields?.maintenanceIsDisplayable;

  // showSpanishToggle is a global sitecore content setting.
  // spanishToggleOverride is a page level sitecore content setting that says
  // to do the opposite of the global setting.
  const shouldShowSpanishToggle = () => {
    const showSpanishToggle = fields?.navigationSettings?.showSpanishToggle;
    const spanishToggleOverride = fields?.navBase?.spanishToggleOverride;
    return showSpanishToggle !== spanishToggleOverride;
  };

  const shouldShowSwitchPortal = () => auth && multiExp;

  const profileLinks = getProfileLinks(fields);

  return {
    accountLink: fields?.myAccountLink?.value?.url,
    accountTxt: getAccountText(fields),
    hamburgerAriaLabel: fields?.hamburgerAriaLabel?.value,
    auth,
    guid: rendering?.uid || '',
    hideTitle: fields?.navBase?.hideSiteTitle,
    isInMaintenance: isInMaintenance(),
    juris: fields?.selectedJurisdiction,
    logo: {
      analytics: {
        action: fields?.logoUrl,
        category: 'top_level_navigation',
        label: 'duke-energy',
        guid: rendering?.uid || '',
      },
      image: { value: fields?.logoImage },
      link: { value: { href: fields?.logoUrl } },
    },
    defaultLogo: {
      analytics: {
        action: fields?.logoUrl,
        category: 'top_level_navigation',
        label: 'duke-energy',
        guid: rendering?.uid || '',
      },
      image: { value: fields?.defaultLogoImage },
      link: { value: { href: fields?.logoUrl } },
    },
    navTitle: {
      href: fields?.navBase?.url,
      text: { value: fields?.navBase?.navigationTitle || 'For Your Home' },
    },
    profileLinks,
    search: {
      href: fields?.searchLink?.value?.url || '/search',
      text: fields?.searchLink?.value?.text || 'Search',
    },
    selectLocationText: fields?.selectLocation?.value || 'Select Location',
    signIn: {
      href: fields?.signInLink?.value?.url,
      text: fields?.signInText || { value: 'Sign In' },
    },
    shouldShowSpanishToggle: shouldShowSpanishToggle(),
    shouldShowSwitchPortal: shouldShowSwitchPortal(),
    stateName: { value: fields?.selectedJurisdiction?.stateName || '' },
    switchPortal: {
      href: fields?.switchPortalLink?.value?.url,
      text: fields?.switchPortalText,
    },
    variant: fields?.variant,
  };
});

export { compositionFunction, getAccountText, getProfileLinks };
export default component;
