import React, { useCallback, useEffect, useRef, useState } from 'react';
import LazyImage from 'src/components/Common/LazyImage';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { stripHTMLTags } from 'src/lib/helpers';
import { AlertProps, GlobalAlertProps } from './types';

const Alert = ({ image, text, title, priority, id }: AlertProps) => {
  const [isOpen, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const [hasArrow, setHasArrow] = useState(false);

  useEffect(() => {
    let resizeObserverEntries: ResizeObserverEntry[] = [];

    const observer =
      typeof ResizeObserver !== 'undefined'
        ? new ResizeObserver(entries => {
            resizeObserverEntries = entries;
            resizeObserverEntries.forEach(entry => {
              const target = entry.target instanceof HTMLElement ? entry.target : null;

              const isMultiLine = () => {
                // line-height calcs between 24 - 26, so just determine if it is more than one line
                const height = target?.scrollHeight ?? 26;
                return height > 26;
              };

              const isTruncated = () => {
                const scrollWidth = target?.scrollWidth ?? 0;
                const offsetWidth = target?.offsetWidth ?? 0;
                return scrollWidth > offsetWidth;
              };

              const hasArrow = isMultiLine() || isTruncated();
              setHasArrow(hasArrow);
            });
          })
        : undefined;

    if (ref.current && observer) {
      observer.observe(ref.current);
    }

    return () => {
      resizeObserverEntries.forEach(entry => entry.target.remove());
      observer?.disconnect();
    };
  }, []);

  // Analytics on Global Alert rendering
  useEffect(() => {
    track.component({
      category: 'global_alerts',
      action: 'impression',
      label: `${title}-|-${stripHTMLTags(text)}`,
      guid: id,
      event: 'event-click',
    });
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const isAnchorTag = (event?.target as HTMLElement)?.tagName === 'A';
    // Don't do anything if the click event came from a RichText anchor tag
    if (isAnchorTag) return;
    // Otherwise toggle the alert
    setOpen(isOpen => !isOpen);

    // Analytics on Global Alert expanding
    track.component({
      category: 'global_alerts',
      action: 'expand',
      label: `${title}-|-${stripHTMLTags(text)}`,
      guid: id,
      event: 'event-click',
    });
  }, []);

  // While resizing, if we switch from the alert text occupying multiple lines
  // to the alert text fitting on a single line, the arrow will dissapear.
  // When this happens, set isOpen state to false bc it is no longer needed -
  // that way if we resize back to a point where the arrow shows again and the alert is truncated,
  // the alert will appropriately appear as closed.

  const toggleFocusOnTag = () => {
    const anchorTag = ref.current?.querySelectorAll('a');
    const hasAnchorTag = anchorTag && anchorTag.length > 0;

    if (!hasAnchorTag) {
      return;
    }

    anchorTag.forEach(tag => {
      isOpen ? tag.removeAttribute('tabindex') : tag.setAttribute('tabindex', '-1');
    });
  };

  useEffect(() => {
    toggleFocusOnTag();
  }, [isOpen]);

  useEffect(() => {
    if (!hasArrow) {
      setOpen(false);
    }
  }, [hasArrow]);

  return (
    <button
      aria-expanded={isOpen}
      aria-label={`toggle ${title}`}
      className="py-12 px-16 md:px-32 bg-yellow"
      onClick={handleClick}
      style={{ order: priority }}
      type="button"
    >
      <div className="container-4xl flex items-start text-left">
        <div className="md:flex flex-1 items-start min-w-0">
          <div className="flex flex-shrink-0 items-center text-blue-dark">
            <LazyImage className="icon-18 fill-current flex-shrink-0" {...image} alt="" />
            <h3 className="ml-12 flex-shrink-0 capitalize font-bold">{title}</h3>
          </div>
          <div
            className={`ml-0 md:ml-24 lg:ml-32 md:mt-0 w-full md:w-auto overflow-hidden ${
              isOpen ? 'max-h-none' : 'max-h-0 md:max-h-none md:h-24'
            }`}
          >
            <RichText
              className={`rich-text-current text-blue-dark mt-8 md:mt-0 ${
                isOpen ? 'js-open' : 'js-closed md:truncate'
              }`}
              aria-hidden={!!(hasArrow && !isOpen)}
              tabIndex={isOpen ? 0 : -1}
              field={text}
              ref={ref}
            />
          </div>
        </div>
        <div
          className={`flex items-center h-48 -my-12 py-12 -mr-16 md:-mr-32 md:ml-24 lg:ml-32 px-16 md:px-32 text-blue-dark ${
            hasArrow ? '' : 'md:hidden'
          }`}
        >
          <span className={`transform duration-200 ${isOpen ? 'scale-y-flip' : ''}`}>
            <SvgLoader focusable="false" color="text-current" name="Arrow" rotate={90} size={20} />
          </span>
        </div>
      </div>
    </button>
  );
};

const GlobalAlerts = ({ items }: GlobalAlertProps) =>
  items.length ? (
    <div className="flex flex-col">
      {items.map((item, index) => (
        <Alert {...item} key={index} />
      ))}
    </div>
  ) : null;

export { Alert };
export default GlobalAlerts;
