import React from 'react';
import Logger from 'src/lib/Logger';
import ErrorBoundary from './index';

export type ErrorComponentType = ({
  error,
}: {
  error: Error;
  defaultTitle?: string;
  defaultMessage?: string;
}) => JSX.Element;

export const defaultTitleContent = 'Error';
export const defaultMessageContent = 'This functionality is currently unavailable.';

export const DefaultErrorComponent: ErrorComponentType = ({
  error,
  defaultMessage = defaultMessageContent,
  defaultTitle = defaultTitleContent,
}) => {
  return (
    <div className="p-16">
      <div className="container-4xl p-24 md:p-32 border border-gray-light rounded-md w-full flex justify-center">
        <div className="container-md text-center">
          <svg
            className="icon-32 md:icon-48 mx-auto text-gray-darker"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill="currentcolor"
            aria-hidden="true"
          >
            <path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32,32-14.327,32-32S49.673,0,32,0Zm-4.681,17.472c0-2.583,2.094-4.677,4.677-4.677s4.677,2.094,4.677,4.677v.11c0,2.583-2.094,4.677-4.677,4.677s-4.677-2.094-4.677-4.677v-.11Zm9.362,29.979c0,2.01-1.629,3.639-3.639,3.639h-2.075c-2.01,0-3.639-1.629-3.639-3.639V28.965c0-2.01,1.629-3.639,3.639-3.639h2.075c2.01,0,3.639,1.629,3.639,3.639v18.487Z"></path>
          </svg>
          <h2 className="text-2xl mt-12 text-gray-darker">{defaultTitle}</h2>
          <div className="rich-text text-md md:text-lg mt-12">
            <p>{defaultMessage}</p>
            {/* We may not want the message immediately accessible to the user, but it is still useful to have it represented in the document */}
            <span className={`text-xs ${process.env.NODE_ENV !== 'production' ? '' : 'sr-only'}`}>
              {error.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Basically we try/catch WrappedComponent render so that we can render ErrorComponent if SSR error is caught
// Reference https://github.com/nayaknotnull/react-isomorphic-error-boundary

type WithErrorBoundary = <Props extends {}>(
  WrappedComponent: React.FC<Props>,
  ErrorComponent?: ErrorComponentType
) => React.ComponentType<Props>;

const ServerBoundary: WithErrorBoundary = (
  WrappedComponent,
  ErrorComponent = DefaultErrorComponent
) => {
  return function Component(props) {
    try {
      return WrappedComponent(props);
    } catch (error: any) {
      const componentName: string = (props as any)?.rendering?.componentName;

      Logger(error, { componentName, message: 'Server Rendering Error' });

      return ErrorComponent({
        error,
      });
    }
  };
};

const ClientBoundary: WithErrorBoundary = (
  WrappedComponent,
  ErrorComponent = DefaultErrorComponent
) => {
  return function Component(props) {
    const componentName: string = (props as any)?.rendering?.componentName;

    return (
      <ErrorBoundary
        render={error => <ErrorComponent error={error} />}
        onCatch={error => Logger(error, { componentName, message: 'Client Rendering Error' })}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
};

const isClient = typeof window !== 'undefined';

// React's ErrorBoundary feature safely handles errors, but this functionality is restricted to Client rendering.
// For SSR we approximate the Client ErrorBoundary by try/catch-ing our component render. NOTE: his technique will NOT catch
// errors deeper in the component tree, but it's a bit better than bailing out of SSR!
const withErrorBoundary = isClient ? ClientBoundary : ServerBoundary;

export default withErrorBoundary;
