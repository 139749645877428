import Composition from 'src/lib/Composition';
import { getModal } from 'src/lib/helpers';
import JSSHero from './index';

export const gradients = {
  'Blue to Blue': 'from-blue to-blue via-blue',
  'Blue Dark to Blue': 'from-blue-dark to-blue via-blue',
  'Blue Dark to Green Dark': 'from-blue-dark to-green-dark via-green-dark',
  'Blue to Teal Darker': 'from-blue to-teal-darker via-teal-darker',
  'Green Dark to Blue Dark': 'from-green-dark to-blue-dark via-blue-dark',
  'Green Dark to Green': 'from-green-dark to-green via-green',
  'Gray Darker to Gray Darker': 'from-gray-darker to-gray-darker via-gray-darker',
  'Gray Light to Gray Lighter': 'from-gray-light to-gray-lighter via-gray-lighter',
  'Gray Lighter to Gray Lighter': 'from-gray-lighter to-gray-lighter via-gray-lighter',
  'Gray Light to White': 'from-gray-light to-white via-white',
  'Teal Light to Teal Lighter': 'from-teal-light to-teal-lighter via-teal-lighter',
  'Teal Darker to Teal Darker': 'from-teal-darker to-teal-darker via-teal-darker',
};

const { compositionFunction, component } = Composition(JSSHero)(({ fields, rendering }) => {
  const headline = fields?.Headline.value;
  const description = fields?.Description.value;
  const theme = fields?.Theme.value;
  const image = fields?.Image;
  const primaryButton = fields ? fields['Primary Button'] : '';
  const secondaryButton = fields ? fields['Secondary Button'] : '';
  const guid = rendering?.uid as string;
  const modal = getModal(fields);

  return {
    headline,
    description,
    theme,
    gradients,
    image,
    primaryButton,
    secondaryButton,
    guid,
    modal,
  };
});

export { compositionFunction, component as default };
