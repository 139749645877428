import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import useMediaQuery from 'src/lib/useMediaQuery';
import { useBodyContext } from '../../ContentWrapper/context';
import { QuickLinksTypes } from './types';

// logic for determining styles depending on the array length and current index
// This is broken out so we can test against it
const classCalc = (index: number, length: number, isMobile: boolean) => {
  // [length, [itemClass, hasDivider, hasBorder]]
  const lengthOptions = new Map([
    [10, ['md:w-1/5', index !== 5, index < 5]],
    [9, ['md:w-1/5', index !== 5, index < 5]],
    [8, ['md:w-1/4', index !== 4, index < 4]],
    [7, ['md:w-1/4', index !== 4, index < 4]],
    [6, ['md:w-1/3', index !== 3, index < 3]],
    [5, ['md:w-1/5', true, false]],
    [4, ['md:w-1/5', true, false]],
    [3, ['md:w-1/5', true, false]],
    [2, ['md:w-1/5', true, false]],
    [1, ['md:w-1/5', false, false]],
  ]);

  // Mapped values
  const option = lengthOptions.get(length) || [];
  const [itemClass, hasDivider, hasBorder] = option;

  // helper methods
  const isLast = () => index === length - 1;
  const isOdd = (num: number) => num % 2 !== 0;
  const isLastRow = () => isLast() || index === length - 2;
  const shouldHaveMobileBorder = () =>
    (isOdd(length) && !isLast()) || (!isOdd(length) && !isLastRow());

  // the bottom border for the <li>
  const getBottomBorderClass = () => {
    if (isMobile && length > 2) {
      if (shouldHaveMobileBorder()) {
        return 'border-gray-light';
      }
    } else if (hasBorder) {
      return 'border-gray-light';
    }
    return 'border-transparent';
  };

  // the left border divider for the child element of the <li>
  const getLeftBorderClass = () => {
    if ((!isMobile && hasDivider && index !== 0) || (isMobile && isOdd(index))) {
      return 'border-gray-light';
    }
    return 'border-transparent';
  };

  return {
    itemClass: `${itemClass} ${getBottomBorderClass()}`,
    childClass: getLeftBorderClass(),
  };
};

const QuickLinksItem = ({
  analytics,
  link,
  image,
  title,
  index = 0,
  length = 0,
  modal,
}: QuickLinksTypes) => {
  const { dispatch } = useBodyContext();
  const isMobile = !useMediaQuery('md');
  const { childClass, itemClass } = classCalc(index, length, isMobile);

  const handleClick = (event: React.MouseEvent) => {
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }
    analytics && track.component(analytics);
  };

  const { Tag, linkProp } = modal?.id
    ? { Tag: 'button', linkProp: {} }
    : { Tag: Link, linkProp: link };

  return (
    <li className={`w-1/2 border-b ${itemClass} py-16 flex justify-center`}>
      <div className={`flex w-full px-12 border-l ${childClass}`}>
        <Tag
          {...(linkProp as any)}
          onClick={handleClick}
          className="w-full h-full text-center group"
        >
          {image && <LazyImage className="mx-auto icon-30 mb-12" {...image} alt="" />}
          {title && (
            <Text
              className="text-teal-darker text-center line-height-s border-b border-transparent transition-colors duration-150 group-hover:border-teal-darker"
              field={title}
              tag="span"
            />
          )}
        </Tag>
      </div>
    </li>
  );
};

const QuickLinks = ({
  backgroundColor,
  items,
}: {
  backgroundColor: string;
  items: Array<QuickLinksTypes>;
}) => {
  const bgColorClass = backgroundColor?.toLowerCase() === 'gray' ? 'bg-gray-lighter' : '';

  return (
    <section className={bgColorClass}>
      <div className="w-full md:container-4xl mx-auto p-16">
        <ul className="flex flex-wrap justify-center list-none w-full">
          {items?.map((props, index) => (
            <QuickLinksItem index={index} length={items.length} {...props} key={index} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export { QuickLinksItem, classCalc, QuickLinks as default };
