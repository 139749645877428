import { PUBLIC_SITE_NAME } from 'src/RouteHandler';
import Logger from 'src/lib/Logger';

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */

interface LayoutServiceParams {
  /** sc_lang */
  lang: string;
  /** sc_site */
  site: string;
}

const getRouteData = (route: string, parmas: LayoutServiceParams) => {
  const apikey = globalConfig.SITECORE_API_KEY;

  const { site, lang } = parmas;

  let item = route;
  // jsspublic should default to "/home" as the root
  if (site === PUBLIC_SITE_NAME) {
    item = route === '/' ? '/home' : route;
  }

  const searchParams = new URLSearchParams();
  searchParams.set('item', item);
  searchParams.set('sc_lang', lang);
  searchParams.set('sc_apikey', apikey!);
  searchParams.set('sc_site', site);

  return fetch(`/sitecore/api/layout/render/jss?${searchParams.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(r => r.json())
    .catch(error => {
      if (error.response && error.response.status === 404 && error.response.data) {
        return error.response.data;
      }

      Logger(error, { message: 'Error in getRouteData' });

      return null;
    });
};

export default getRouteData;
