import { AccordionGroup, AccordionWrapper } from 'src/components/Common/@electron/Accordion';
import { useSitecoreContext } from 'src/lib/SitecoreContextFactory';
import StockTicker, { getProps } from './index';

const TickerWithContext = ({
  variant,
  image,
  investorsLink,
}: {
  variant?: 'Accordion';
  image?: JSS.ImageField;
  investorsLink?: JSS.LinkField;
}) => {
  const { getComponent } = useSitecoreContext();
  const [data] = getComponent('Hero Stock Ticker');

  if (!data) return null;

  const stockTickerFeed = data?.fields?.stockTickerFeed;

  if (!stockTickerFeed) {
    return null;
  }

  const props = getProps(data);

  if (!props) {
    return null;
  }

  const StockTickerComponent = <StockTicker image={image} link={investorsLink} {...props} />;

  if (variant === 'Accordion') {
    return (
      <div className="px-16">
        <AccordionWrapper>
          <AccordionGroup
            buttonClass="text-xl pr-24 lg:pr-0 uppercase"
            buttonTitle={{ value: 'Stock Information' }}
          >
            {StockTickerComponent}
          </AccordionGroup>
        </AccordionWrapper>
      </div>
    );
  }

  return StockTickerComponent;
};

export const HeroStockTicker = ({
  showStock,
  ...rest
}: {
  variant?: 'Accordion';
  image?: JSS.ImageField;
  investorsLink?: JSS.LinkField;
  showStock?: boolean;
}) => {
  if (!showStock) {
    return null;
  }

  return <TickerWithContext {...rest} />;
};
