import { Text } from '@sitecore-jss/sitecore-jss-react';
import Button from 'src/components/Common/@electron/Button';
import LazyImage from 'src/components/Common/LazyImage';
import RichText from 'src/components/Common/RichText_Old';
import track from 'src/lib/Analytics';
import useMediaQuery from 'src/lib/useMediaQuery';
import { HeroType } from './types';

const HeroSmall = ({
  analytics,
  desktopImage,
  mobileImage,
  headline,
  subhead,
  mobileHeadline,
  ctaLink,
  variant = 'blue-base',
  isButton = false,
  hideImage = false,
}: HeroType) => {
  const isMobileViewport = !useMediaQuery('md');

  const getImgHidden = () => {
    if (hideImage || (!desktopImage && !mobileImage)) {
      return isMobileViewport;
    }
    return false;
  };

  const showTextSide = () => {
    return !!(title || subhead || ctaLink?.value?.href || ctaLink?.value?.text);
  };

  const colorMap = {
    'gray-base': 'bg-gray text-blue',
    'green-base': 'bg-green-dark text-white',
    'teal-base': 'bg-teal-light text-blue',
    'blue-base': 'bg-blue text-white',
  };

  const getTitleAndImage = () => {
    return isMobileViewport
      ? { image: mobileImage, title: mobileHeadline }
      : { image: desktopImage, title: headline };
  };

  const { title, image } = getTitleAndImage();
  const imgHidden = getImgHidden();
  const showText = showTextSide();

  return (
    <div className={`${colorMap[variant]} w-full`}>
      <div className="color-current relative m-0">
        {!imgHidden && (
          <LazyImage
            className="object-cover w-full container-4xl"
            {...image}
            style={{ maxHeight: '340px' }}
          />
        )}
        {showText && (
          <div
            className={`js-showText ${colorMap[variant]} pt-24 pb-24 pl-54 pr-54 md:pt-24 md:pb-24 md:pl-16 md:pr-16 text-center md:left-0 md:h-full md:absolute md:top-0 md:text-left md:w-1/2`}
          >
            {title && <Text className="text-2xl md:text-3xl" field={title} tag="h2" />}
            {subhead && (
              <RichText className="text-md md:text-l mt-2 text-white" tag="p" field={subhead} />
            )}
            <Button
              {...(ctaLink?.value?.href ? { href: ctaLink.value.href } : {})}
              variant={isButton ? 'primary-reversed' : 'text-reversed'}
              onClick={() => track.component(analytics)}
            >
              {ctaLink.value.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSmall;
