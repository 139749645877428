import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SvgLoader from 'src/components/Common/SvgLoader';
import { fieldValidation } from 'src/lib/createForm/utils/forms';
import { stripHTMLTags, toKebabCase } from 'src/lib/helpers';
import ErrorMessage from '../ErrorMessage';
import { FormSelectProps, SelectProps } from './types';

const Select = ({
  disabled = false,
  error,
  items,
  label,
  defaultValue,
  onChange,
  inputRef,
  ...rest
}: SelectProps) => {
  const [labelAbove, setLabelAbove] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { hasError, errorMessage } = error;
  const errorId = `${toKebabCase(errorMessage)}-ErrorID`;
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = event.target.selectedIndex;
    setLabelAbove(true);
    onChange && onChange({ text: event.target?.options?.[index]?.text, value: event.target.value });
    return event.target.value;
  };

  const labelClasses = () => {
    const baseClass =
      'absolute left-0 bottom-0 transform -translate-y-4 transition-all duration-150 ease-in-out pointer-events-none';

    const aboveClass = labelAbove ? 'opacity-100 text-xs top-0 -mt-16' : 'opacity-0 text-sm z-hide';

    const colorClass = isFocused ? 'text-teal-darker' : 'text-gray-dark';

    const errorClass = hasError ? 'text-red' : '';

    return `${baseClass} ${aboveClass} ${colorClass} ${errorClass}`;
  };

  return (
    <div className="pl-0 pb-48 ">
      <div className={`relative ${disabled ? 'opacity-40' : ''}`}>
        <div className="flex">
          <select
            className={`w-full relative mr-16 pr-16 border-b pb-2 transform -translate-y-2 text-gray-dark bg-transparent cursor-pointer appearance-none ${
              hasError ? 'border-red' : 'focus:border-teal-darker border-gray-dark'
            }`}
            defaultValue={defaultValue || label}
            aria-describedby={errorId}
            aria-invalid={hasError}
            id={label}
            onBlur={() => setIsFocused(false)}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            ref={inputRef}
            {...{ disabled, ...rest }}
          >
            <option disabled value={label}>
              {label}
              {rest.required ? ' *' : ''}
            </option>
            {items.map(({ value, text = value }, index) => (
              <option key={index} value={value || text}>
                {text}
              </option>
            ))}
          </select>
          {label && (
            <label htmlFor={label} className={labelClasses()}>
              {label}
            </label>
          )}
          <SvgLoader
            color="text-teal-darker"
            name="Triangle"
            rotate={180}
            size={16}
            className="-ml-32 mt-4 pointer-events-none"
          />
        </div>
        {hasError && <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>}
      </div>
    </div>
  );
};

const FormSelect = ({ data, errors }: FormSelectProps) => {
  // react-hook-form setups
  // We need to use the Controller because we need to pass through a
  // custom onChange handler since Sitecore is expecting this value not as a string but
  // an object
  const { control } = useFormContext();

  // destructure data
  const { items, label = '', name, required } = data;

  const { isRequired } = fieldValidation;

  // make sure we're only passing through plain strings
  const itemsWithNoTags = () =>
    items.map(({ text, value }) => ({
      text: stripHTMLTags(text) || '',
      value: stripHTMLTags(value) || '',
    }));

  const selectProps = () => ({
    error: { hasError: Boolean(errors?.[name]?.message), errorMessage: errors?.[name]?.message },
    items: itemsWithNoTags(),
    label,
    name,
    required: Boolean(required),
  });

  if (!items?.length) return null;

  return (
    <Controller
      render={({ field }) => (
        <Select
          onChange={(obj: object) => field.onChange(obj)}
          {...selectProps()}
          inputRef={field.ref}
        />
      )}
      rules={{ required: isRequired(data) }}
      defaultValue=""
      {...{ control, name }}
    />
  );
};

export { Select, FormSelect as default };
