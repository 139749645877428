import React, { useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import setJurisdictionPerZipCode from 'src/api/duke/setJurisdictionPerZipCode';
import Button from 'src/components/Common/@electron/Button';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { StepProps } from 'src/components/Common/Jurisdiction/types';
import SvgLoader from 'src/components/Common/SvgLoader';
import ErrorMessage from 'src/components/Public/Form/_components/ErrorMessage';
import { labelClasses } from 'src/components/Public/Form/_components/Input/components/classes';
import track from 'src/lib/Analytics';
import { masks } from 'src/lib/createForm/utils/forms';

const errorId = 'jurisdiction-error';

const ZipForm = ({
  headline,
  id,
  sidebar = false,
}: {
  headline: StepProps['headline'];
  id: string;
  sidebar?: boolean;
}) => {
  const { set, whichState } = useStep();
  const { handleSubmit } = useForm({ mode: 'onChange' });
  const [loading, toggle] = useReducer(v => !v, false);

  const [state, setState] = useState({
    inputValue: '',
    isFocused: false,
    labelAbove: false,
    hasError: false,
  });

  const handleAnalytics = () => {
    const analyticsCategory = whichState?.name.split(' ').pop();
    const analyticsLabel = headline?.value?.replace(/\W[{0}]\W/, whichState?.name || '');

    track.component({
      action: 'zip-code',
      category: `jurisdiction_change-${analyticsCategory}`,
      guid: id,
      label: analyticsLabel,
    });
  };

  const onSubmit = async () => {
    toggle();
    const stateAbbreviation = whichState?.name === 'North Carolina' ? 'NC' : 'SC';

    try {
      const data = await setJurisdictionPerZipCode({
        stateAbbreviation,
        zipCode: state.inputValue,
      });
      handleAnalytics();
      return set('Loading', data);
    } catch (error) {
      return set('Error');
    }
  };

  const inputClasses = (hasError: boolean) => {
    const baseClass =
      'relative bg-transparent border-b focus:border-transparent text-gray-dark w-full outline-none rounded-none text-md-fixed z-10';
    const errorClass = hasError ? 'border-red' : '';
    return `${baseClass} ${errorClass}`.trim();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const val = masks.numbers(value);
    setState({
      ...state,
      inputValue: val,
      hasError: !!val && val.length < 5,
    });

    event.target.value = val;
  };

  const handleFocus = () => {
    setState({ ...state, isFocused: true, labelAbove: true });
  };

  const handleBlur = () => {
    setState({ ...state, isFocused: false, labelAbove: !!state.inputValue });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={
        sidebar
          ? 'flex items-center justify-between p-16'
          : 'flex items-end md:justify-center gap-16 py-16'
      }
    >
      <div className={sidebar ? 'pl-0 pb-28' : '-mb-24 pl-0 pb-48'}>
        <div className="relative">
          <input
            autoComplete="postal-code"
            aria-invalid={state.hasError}
            aria-describedby={errorId}
            className={inputClasses(state.hasError)}
            id="zip"
            maxLength={5}
            minLength={5}
            name="ZipCode"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
            type="text"
          />
          <label
            className={labelClasses({
              hasError: state.hasError,
              isAbove: state.labelAbove,
              hasIcon: false,
              isFocused: state.isFocused,
            })}
            htmlFor="zip"
          >
            Zip Code
          </label>
          {state.hasError && <ErrorMessage id={errorId}>Must be a valid Zip Code</ErrorMessage>}
        </div>
      </div>
      <Button
        variant="secondary"
        size="md"
        aria-disabled={state.inputValue?.length < 5}
        className="ml-16 mb-24"
      >
        {loading ? (
          <SvgLoader
            name="LoadingCircle"
            className="inline mr-8"
            size={12}
            rotate={180}
            aria-hidden={true}
            focusable={false}
          />
        ) : (
          'continue'
        )}
      </Button>
    </form>
  );
};

export default ZipForm;
