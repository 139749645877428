import { useAppContext } from 'src/lib/Contexts/AppContext';
import setJurisdictionCookie from 'src/lib/setJurisdictionCookie';
import { useLocation } from 'react-router-dom';
import { JurisdictionCodes } from '../Jurisdiction/types';
import { useEffect } from 'react';

const PageLoad = () => {
  const appContext = useAppContext();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const jur: any = params?.jur;
  useEffect(() => {
    if (jur && JurisdictionCodes.indexOf(jur.toUpperCase()) !== -1) {
      const doFetch = async () => {
        await setJurisdictionCookie(jur);
        await appContext.mutate();
      };
      doFetch();
    }
  }, []);
  return null;
};

export default PageLoad;
