import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import getCookies from 'src/lib/Cookies';

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { status } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const cookie = getCookies();
  const isImpersonatedCookie = !!cookie?.['de.identity'];

  const url = location.pathname;
  const userIsAuthenticated = status.isAuthenticated;
  // TODO: add authPages to come from sitecore
  const isAuthPage =
    url === '/my-account/multiple-accounts-table' ||
    url === '/my-account/sign-in/disambiguationpage';
  const shouldRedirect = !userIsAuthenticated && !isImpersonatedCookie && isAuthPage;

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/my-account/sign-in');
    }
  }, [shouldRedirect]);

  return <>{children}</>;
};

export default AuthGuard;
