import { createContext, useContext, useEffect, useReducer } from 'react';
import { getUserExperiences } from 'src/components/Auth/Disambiguation/_api/getUserExperiences';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import Logger from 'src/lib/Logger';

/** property name used to hold JSON.strigified AuthContext state in sessionStorage */
const authContextKey = 'authContext';

type State = {
  profExperience: {
    experienceName: string;
    label: string;
    token: string;
  }[];
  isImpersonated: boolean;
  hasNewExperience: boolean;
  email: string;
};

type Action = {
  type: 'GET_USER_EXPERIENCES';
  payload: Awaited<ReturnType<typeof getUserExperiences>>;
};

const initialState = {
  profExperience: [],
  isImpersonated: false,
  hasNewExperience: false,
  email: '',
};

export const AuthContext = createContext({
  state: {} as State,
  dispatch: {} as React.Dispatch<Action>,
});

const authReducer = (state: State, action: Action) => {
  const { payload, type } = action;

  switch (type) {
    case 'GET_USER_EXPERIENCES': {
      return {
        ...state,
        profExperience: payload.profExperience,
        isImpersonated: payload.isImpersonated === 'true',
        hasNewExperience: payload.hasNewExperience,
        email: payload.email,
      };
    }
    default:
      throw new Error('Please pass an action type');
  }
};

const getInitialSession = (initial: typeof initialState) => {
  if (typeof window !== 'undefined') {
    let contextState = initial;

    const storedJSON = sessionStorage.getItem(authContextKey);
    if (storedJSON) {
      try {
        contextState = JSON.parse(storedJSON);
      } catch (e) {
        Logger(e, {
          message: 'AuthContext::userExperiences: Invalid JSON in sessionStorage',
          componentName: 'AuthCtxProvider',
        });
      }
    }

    return contextState;
  }
  return initial;
};

/** sets State in sessionStorage on updates */
const useSyncWithSessionStorage = (state: State) => {
  const { status } = useAppContext();
  const { isAuthenticated, isImpersonated, externalID } = status;

  // If the user is impersonating and has email in auth context already, compare
  const impersonationCheck: boolean =
    isImpersonated && externalID && state.email ? externalID === state.email.toUpperCase() : true;

  useEffect(() => {
    if (!isAuthenticated || !impersonationCheck) {
      sessionStorage.removeItem(authContextKey);
    } else {
      sessionStorage.setItem(authContextKey, JSON.stringify(state));
    }
  }, [state, isAuthenticated]);
};

const AuthCtxProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState, getInitialSession);

  useSyncWithSessionStorage(state);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthCtxProvider, initialState, useAuthContext };
