/* eslint-disable complexity */
import React, { useRef, useState, useEffect } from 'react';
import useMediaQuery from 'src/lib/useMediaQuery';

const states = {
  fixedTop: {
    className:
      'fixed left-0 right-0 bottom-full transition-transform duration-300 transform translate-y-full shadow',
  },
  offScreen: {
    className: 'fixed left-0 right-0 bottom-full transition-transform duration-300 transform',
  },
  idle: {
    className: '',
  },
};

let prevScrollPosition = 0;

const Wrapper = ({
  headerRef,
  stickyClass = states.idle.className,
  children,
}: React.PropsWithChildren<{
  headerRef?: React.MutableRefObject<HTMLDivElement | null>;
  stickyClass?: string;
}>) => {
  return (
    <div className="js-site-header relative bg-white z-header" ref={headerRef}>
      <div className={`js-site-header-container border-t-6 border-blue ${stickyClass}`}>
        {children}
      </div>
    </div>
  );
};

// A "Shy" Nav hides when scrolling down, but reappears when scrolling up
const ShyNav = ({ children }: React.PropsWithChildren<{}>) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [stickyClass, setClass] = useState(states.idle.className);

  useEffect(() => {
    let headerHeight = 10;

    const setHeight = () => {
      if (headerRef.current) {
        headerHeight = (headerRef.current?.firstChild as HTMLDivElement).offsetHeight;
        headerRef.current.style.minHeight = `${headerHeight}px`;
      }
    };

    const handleScroll = ({ currentTarget }: Event) => {
      if (!headerRef.current) {
        return;
      }

      const headerDimensions = headerRef.current.getBoundingClientRect();

      const headerDistanceFromTop = headerDimensions.top;
      const headerHeight = headerDimensions.height;

      const scrollingDown = prevScrollPosition < (currentTarget as Window).scrollY;
      const headerOutOfView = headerDistanceFromTop + headerHeight < 0;

      // offScreen
      const offScreen = headerOutOfView && scrollingDown;
      if (offScreen) {
        setClass(states.offScreen.className);
      }

      // fixedTop
      const scrollingUp = !scrollingDown;
      const fixedTop = headerOutOfView && scrollingUp;
      if (fixedTop) {
        setClass(states.fixedTop.className);
      }

      // idle
      const headerAtTop = headerDistanceFromTop >= 0;
      const idle = headerAtTop;
      if (idle) {
        setClass(states.idle.className);
      }

      prevScrollPosition = window.scrollY;
    };

    setHeight();

    // TODO Throttle event listeners
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', setHeight, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <Wrapper headerRef={headerRef} stickyClass={stickyClass}>
      {children}
    </Wrapper>
  );
};

const StickyNav = (props: React.PropsWithChildren<{}>) => {
  const isMd = useMediaQuery('md');

  if (isMd) {
    return <ShyNav {...props} />;
  }

  // The tailwind "sticky" class is added in src/Layout.tsx
  return <Wrapper {...props} />;
};

export default StickyNav;
