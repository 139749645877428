import Composition from 'src/lib/Composition';
import { getBackgroundColor, getModal } from 'src/lib/helpers';
import { getHeadline, getImage, getLink } from './compositionHelpers/helpers';
import CallToAction from './index';

const { compositionFunction, component } = Composition(CallToAction)(
  ({ fields = {}, rendering }) => {
    const link = fields?.['Call to Action'] || getLink(fields);
    const mobileLink = fields?.['Call to Action Mobile'];
    const headlineObj = getHeadline(fields);
    const title = headlineObj && fields[headlineObj];

    return {
      analytics: {
        category: rendering?.componentName || 'Call To Action',
        label: title?.value,
        action: [link?.value?.text, link?.value?.href].filter(Boolean).join(' | '),
        guid: rendering?.dataSource || '',
      },
      backgroundColor: getBackgroundColor(fields),
      ctaText: fields?.['CTA Rich Text'] || fields?.CTARichText,
      image: getImage(fields),
      link,
      mobileLink: mobileLink?.value?.href ? mobileLink : link,
      modal: getModal(fields),
      title,
      subtitle: fields?.Subhead,
      isCecCTA: link?.value?.portaltokenauth,
    };
  }
);

export { compositionFunction, component as default };
