import { useEffect, useRef } from 'react';
import { Overlay } from 'src/components/Common/@electron/Drawer';
import { StepProvider, useStep } from 'src/components/Common/Jurisdiction/context';
import { JurisdictionProps, StepProps } from 'src/components/Common/Jurisdiction/types';
import { FocusTrap, getChildNodes } from 'src/lib/useFocusTrap';
import { ErrorView, Intro, List, Loading, Map, Zip, descriptionId, headlineId } from './Steps';

const compMap = {
  Intro,
  Map,
  Zip,
  Loading,
  Error: ErrorView,
  List,
};

const Main = ({
  states,
  steps,
  useZipText,
  backToStatesText,
}: JurisdictionProps & { useZipText?: string }) => {
  const { whichStep } = useStep();
  const StepComponent = compMap[whichStep];
  const getProps = (which: string) => steps?.find(({ name }) => name === which);
  const selectedStep = getProps(whichStep);
  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const [first] = getChildNodes(containerRef.current);
    first?.focus();

    return () => {
      if (whichStep === 'Loading') {
        document.getElementById('maybe-hidden-content')?.focus();
      }
    };
  }, [whichStep]);

  if (!selectedStep) {
    return null;
  }
  return (
    <FocusTrap
      ref={containerRef}
      shouldTrap={true}
      className="px-16 md:px-32 pb-24 md:pb-32 pt-24 md:pt-48 text-center container-4xl"
      data-testid={whichStep}
    >
      <StepComponent
        states={states}
        {...selectedStep}
        useZipText={useZipText}
        backToStatesText={backToStatesText}
      >
        {whichStep === 'Error' && <Map {...(getProps('Map') as StepProps)} hasZip={false} />}
      </StepComponent>
    </FocusTrap>
  );
};

const Intercept = (props: JurisdictionProps) => {
  // TODO: DNT-1672 Add Geolocation detection
  // Without geolocation we skip straight to List screen
  return (
    <StepProvider initialStep="List">
      <Overlay isOpen={true}>
        <div
          className="w-full h-full fixed top-0 flex flex-col justify-end"
          aria-labelledby={headlineId}
          aria-describedby={descriptionId}
          aria-modal={true}
          role="dialog"
          tabIndex={-1}
        >
          <div className="bg-white w-full flex-shrink overflow-auto">
            <Main {...props} />
          </div>
        </div>
      </Overlay>
    </StepProvider>
  );
};

export default Intercept;
