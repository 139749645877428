import { compositionFunction as ctaComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/Composition';
import { setupAnalytics } from './analytics';
import data from './data';
import NewsAndResources from './index';

type JssProps<T extends { fields: any }> = { fields: T['fields']; rendering?: T };

const { compositionFunction, component } = Composition(NewsAndResources)<JssProps<typeof data>>(
  props => {
    // Apply analytics events with shared values,
    // returns function that accepts specific event parameters
    const track = setupAnalytics({
      headline: props.fields?.Headline?.value,
      guid: props.rendering?.uid,
    });

    const resources = props.fields?.Resources?.map(item => {
      const title = item.fields?.Title?.value;
      const file = item.fields?.['Media File']?.value?.href;
      const trackFunction = () => track({ label: 'download', action: `${title} | ${file}` });

      return {
        id: item.id,
        type: item.fields?.['Resource Type']?.fields?.Setting?.value,
        title: item.fields?.Title?.value,
        date: item.fields?.['Publish Date']?.value,
        file: item.fields?.['Media File'],
        track: trackFunction,
      };
    }).filter(item =>
      // Sitecore may allow content author to save item without adding all the data we need,
      // we only want items with fleshed out data
      [item.file?.value?.href, item.type, item.title].every(Boolean)
    );

    // Make array of unique resource type options for the <select> element
    // (remove duplicates bc we only need one representation of each type)
    const resourceTypes = Array.from(new Set(['All', ...resources.map(({ type }) => type)])).map(
      value => ({
        value,
      })
    );

    return {
      ...ctaComposition(props),
      resources,
      resourceTypes,
      track,
    };
  }
);

export { compositionFunction };
export default component;
