import { useMemo } from 'react';
import SvgLoader from 'src/components/Common/SvgLoader';
import { CopyrightAndLinks, SiteMap, SocialLinksAndAppBadges } from './components';
import { FooterProps } from './types';

const Tagline = () => (
  <div className="px-16 md:px-24 py-24 md:py-32 border-b border-teal-darker">
    <div className="container-4xl">
      <SvgLoader
        sizeConstraints={false}
        aria-label="Building a Smarter Energy Future"
        className="mx-auto h-16 w-auto"
        color="text-current"
        name="DukeTagline"
      />
    </div>
  </div>
);

const Footer = ({
  badges,
  bottomLinks,
  copyright,
  columns,
  flatColumn,
  socialLinks,
  variant = 'FatFooter',
}: FooterProps) => {
  const { isFat, isSkinnySocial } = useMemo(
    () => ({
      isFat: variant === 'FatFooter',
      isSkinnySocial: ['FatFooter', 'Social Toolbar and Skinny Footer'].includes(variant),
    }),
    [variant]
  );

  return (
    <div className="bg-blue text-white">
      <Tagline />
      {isSkinnySocial && <SocialLinksAndAppBadges badges={badges} socialLinks={socialLinks} />}
      {isFat && <SiteMap columns={columns} flatColumn={flatColumn} />}
      <CopyrightAndLinks copyright={copyright} items={bottomLinks} />
    </div>
  );
};

export default Footer;
