import React from 'react';

interface UseFocusTrap {
  shouldTrap: boolean | undefined;
  container: HTMLElement | null;
  onEnter?: (childNodes: HTMLElement[]) => void;
  onExit?: () => void;
}

const isHTMLElement = (el: unknown): el is HTMLElement => el instanceof HTMLElement;

const getChildNodes = (container: HTMLElement | null) => {
  if (!isHTMLElement(container)) {
    // TODO maybe dev only? proccess.emv.NODE_ENV === 'development'
    // eslint-disable-next-line no-console
    console.error(`Cannot get child nodes, ${container} is not an HTMLElment`);
    return [];
  }

  const focusableTags =
    '[href], button, textarea, input, select, details, iframe, [tabindex]:not([tabindex="-1"]';
  // get a list of child nodes in the trap container's content
  const childNodes = Array.from(container.querySelectorAll(focusableTags)).filter(
    node => !node.getAttribute('aria-hidden') && !node.hasAttribute('disabled')
  );
  return childNodes as HTMLElement[];
};

const useFocusTrap = ({ container, onExit, onEnter, shouldTrap }: UseFocusTrap) => {
  const previouslyFocusedElement = React.useRef<HTMLElement | null>(null);

  const handleExit = () => {
    onExit?.();
  };

  // `useLayoutEffect` runs before browser paint; at this point `document.activeElement`
  // has not yet been updated to the focus trap container so we can grab that HTMLElement and
  // store it in a ref to use later.
  React.useLayoutEffect(() => {
    if (shouldTrap && isHTMLElement(document.activeElement)) {
      previouslyFocusedElement.current = document.activeElement;
    }
  }, [shouldTrap]);

  React.useEffect(() => {
    /**  prevents tabbing outside of the trap */
    const handleTab = (event: KeyboardEvent) => {
      if (!shouldTrap || !isHTMLElement(container)) {
        return;
      }

      const childNodes = getChildNodes(container);

      const isChildNode = container?.contains(document.activeElement);
      const lastNode = childNodes[childNodes.length - 1];
      const firstNode = childNodes[0];
      const isFirstNode = document.activeElement === firstNode;
      const isLastNode = document.activeElement === lastNode;
      const isContainer = document.activeElement === container;

      /** tab, moves focus forward */
      const handleForward = () => {
        if (isLastNode) {
          event.preventDefault();
          isHTMLElement(firstNode) && firstNode.focus();
        }
      };

      /** shift + tab, moves focus backward */
      const handleBackward = () => {
        if (isFirstNode || isContainer) {
          event.preventDefault();
          isHTMLElement(lastNode) && lastNode.focus();
        }
      };

      if (isChildNode) {
        event.shiftKey ? handleBackward() : handleForward();
      } else {
        // The document.activeElement is not a childNode, e.g. user is focused on url bar
        // when they tab onto the page we need to send focus to the first element
        event.preventDefault();
        firstNode.focus();
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Tab':
          return handleTab(event);
        case 'Escape':
          return handleExit();
        default:
          return event;
      }
    };

    if (shouldTrap && document.hasFocus()) {
      if (onEnter) {
        onEnter(getChildNodes(container));
      }

      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      previouslyFocusedElement.current?.focus();

      document.removeEventListener('keydown', handleKeydown);
    };
  }, [shouldTrap]);
};

export interface ModalProps {
  /** description: Supporting subtitle text displayed on second line. */
  description?: React.ReactNode;
  /** descriptionVariant: text alignment. */
  descriptionVariant?: 'text-left' | 'text-center';
  /** title: Primary text labelling the modal dialog. */
  title: React.ReactNode;
  /** titleVariant: text alignment. */
  titleVariant?: 'text-left' | 'text-center';
  /** isOpen: Triggers the modal to animate open. */
  isOpen?: boolean;
  /** onClose: Callback that is invoked when the modal closes. */
  onClose?: () => void;
  /** variant: Design options. */
  variant?: 'close-button' | 'no-close-button' | 'sheet';
  /** id: Unique identifier (required). */
  id: string;
  /** children: Elements in the body content of the modal. */
  children?: React.ReactNode;
}

export const Modal = ({
  isOpen,
  description,
  title,
  onClose,
  variant,
  id,
  children,
  titleVariant = 'text-left',
  descriptionVariant = 'text-left',
}: ModalProps) => {
  const titleId = `${id}-modal-title`;
  const descriptionId = `${id}-modal-description`;

  const dialogRef = React.useRef<HTMLDivElement>(null);
  useFocusTrap({
    shouldTrap: isOpen,
    container: dialogRef.current,
    onEnter: () => dialogRef.current?.focus(),
    onExit: onClose,
  });

  return (
    // Not really a static element interaction, we just want a click listener on the element to close the modal
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={`fixed inset-0 z-modal ${isOpen ? '' : 'pointer-events-none'}`}
      onClick={e => {
        // only fire close event when clicking outside of modal
        if (onClose && e.target instanceof HTMLElement && !dialogRef.current?.contains(e.target)) {
          onClose();
        }
      }}
    >
      {/** Overlay background */}
      <div
        className={`absolute inset-0 bg-gray-darker transition-opacity ${
          isOpen
            ? 'bg-opacity-80 ease-out duration-100 visible'
            : 'bg-opacity-0 ease-in duration-150 invisible'
        }`}
        aria-hidden="true"
      ></div>
      {/** Modal content */}
      <div
        className={`absolute inset-0 flex justify-center items-end sm:items-center md:p-16 ${
          variant === 'sheet' ? '' : 'p-12'
        }`}
      >
        <div
          className={`relative p-16 pt-48 sm:p-48 md:p-64 max-h-full rounded-md shadow overflow-auto bg-white transition-all ${
            isOpen
              ? 'opacity-100 transform scale-100 translate-y-0 ease-out duration-200'
              : 'opacity-0 transform scale-95 translate-y-1/3 sm:translate-y-0 ease-in duration-75'
          } ${variant === 'sheet' ? 'w-full sm:w-auto' : ''}`}
          role="dialog"
          aria-modal="true"
          aria-hidden={!isOpen}
          aria-labelledby={titleId}
          // if description is undefined, div with id={descriptionId} will not render
          // only create aria-describedby if description is present
          {...(description && { 'aria-describedby': descriptionId })}
          ref={dialogRef}
          // eslint rule is NOT relevant here, we want to programatically focus the modal so that screen reader will announce ontext starting at the dialog aria-labelledby
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={-1}
          // "inert" is new-ish (writing this at end of 2022): https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
          // eslint / ts / react don't know about it yet so we set it to string to add it to the DOM and empty {} to remove it completely.
          // "inert" prevents contents from being tabbable.
          {...(isOpen ? {} : { inert: 'true' })}
        >
          {/** Close button */}
          <div className="container-md flex flex-col h-full" role="document">
            <div className="rich-text">
              <h2 className={titleVariant} id={titleId}>
                {title}
              </h2>
              {description && (
                <div className={descriptionVariant} id={descriptionId}>
                  {description}
                </div>
              )}
            </div>
            {/** Having the close button at this level with all the potentially interactive content in children allows the
             * screen reader to announce the aria-labelledby title of the dialog. If the button was outside this content container
             * the button label would we announced in place of the dialog aria-labelledby attribute.
             */}
            {variant !== 'no-close-button' && (
              <button
                className="absolute p-16 md:p-8 top-0 md:top-16 right-0 md:right-16 transform hover:scale-110 transition-transform ease-in-out duration-300"
                type="button"
                aria-label="Close Dialog"
                onClick={onClose}
              >
                <svg
                  className="fill-current text-teal-darker icon-16 md:icon-20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M2.782.477,12,9.7,21.218.477a1.63,1.63,0,0,1,2.3,2.305L14.305,12l9.218,9.218a1.63,1.63,0,0,1-2.3,2.3L12,14.305,2.782,23.523a1.63,1.63,0,0,1-2.305-2.3L9.7,12,.477,2.782A1.63,1.63,0,0,1,2.782.477Z" />
                </svg>
              </button>
            )}
            {/** children is anything else you want to put in the modal */}
            {/** (it looks pretty good to separate children by "24" on the spacing scale ... "mt-24") */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
