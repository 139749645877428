import Composition from 'src/lib/Composition';
import AppBanner from './index';
import data from './data';

const { component, compositionFunction } = Composition(AppBanner)<typeof data>(props => {
  const { dataSource } = props.fields;
  return {
    link: dataSource['Data Link'],
    ctaText: dataSource['CTA Text'],
    title: dataSource.Title,
    subtitle: dataSource.Subtitle,
    body: dataSource['Get Details'],
    expiration: dataSource['Days To Expire']?.value,
    image: dataSource.Image,
  };
});

export { compositionFunction };
export default component;
