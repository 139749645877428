import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Button from 'src/components/Common/@electron/Button';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import Link from 'src/components/Common/Link';
import RichText from 'src/components/Common/RichText_Old';
import SvgLoader from 'src/components/Common/SvgLoader';
import { HeroStockTicker } from 'src/components/Public/StockTicker/HeroStockTicker';
import track from 'src/lib/Analytics';
import { pxPush } from 'src/lib/Analytics/px';
import { screens } from 'src/lib/Theme';
import { useExperienceEditor } from 'src/lib/useExperienceEditor';
import useMediaQuery from 'src/lib/useMediaQuery';
import { HeroComponentProps, HeroCtaProps, HeroProps } from './types';

const HeroCta = ({ analytics, ctaType, isCtaAButton, link, modal, buttonColor }: HeroCtaProps) => {
  const { dispatch } = useBodyContext();
  const isVideoSlide = ctaType === 'VideoSlide' && !isCtaAButton;
  const btnVariant = buttonColor === 'Light' ? 'primary-reversed' : 'primary';

  const handleClick = (event: React.MouseEvent) => {
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }
    if (analytics) {
      track.component(analytics);
    }
    pxPush({ eventName: 'hero', clickText: link?.value?.text });
  };

  return (
    <Link
      {...link}
      className="flex mx-auto uppercase relative sm:inline sm:mr-auto sm:ml-0"
      onClick={handleClick}
    >
      {isVideoSlide && (
        <SvgLoader
          name="Play"
          color="text-current"
          size={24}
          className="inline relative bottom-0 mr-12"
        />
      )}
      {isCtaAButton ? (
        <Button variant={btnVariant} tabIndex={-1}>
          {link?.value?.text}
        </Button>
      ) : (
        <span className="border-current text-current sm:pb-2 capitalize border-b border-opacity-80 sm:border-b-2 sm:border-opacity-100 sm:uppercase">
          {link?.value?.text}
        </span>
      )}
    </Link>
  );
};

const HeroComponent = ({
  isDark,
  link,
  subtitle,
  title,
  slotContent,
  ...rest
}: HeroComponentProps) => {
  const color = isDark ? 'text-blue-dark' : 'text-white';

  return (
    <div className="absolute inset-0 flex justify-between items-center gap-16 h-full sm:container-lg lg:container-2xl xl:container-4xl">
      <div
        className={`${color} w-full h-full mx-auto px-24 text-center filter drop-shadow flex flex-col justify-center md:px-0 sm:mx-0 sm:text-left sm:max-w-md`}
      >
        {title && (
          <Text
            className="text-current text-2xl mb-12 sm:text-3xl sm:mb-16"
            field={title}
            tag={subtitle?.value ? 'h2' : 'p'}
          />
        )}
        {subtitle && (
          <RichText
            className="text-current text-md mb-16 sm:text-xl sm:mb-24"
            tag="p"
            field={subtitle}
          />
        )}
        {link && <HeroCta {...{ link, ...rest }} />}
      </div>
      {slotContent}
    </div>
  );
};

const Hero = ({
  color,
  image,
  link,
  mobileImage,
  mobileSubtitle,
  mobileTitle,
  stockTickerOptions,
  subtitle,
  title,
  ...rest
}: HeroProps) => {
  const { isEEActive } = useExperienceEditor();
  const isMobile = !useMediaQuery('md');
  const isDark = color === 'Dark';
  const { compSubtitle, compTitle } = isMobile
    ? { compSubtitle: mobileSubtitle, compTitle: mobileTitle }
    : { compSubtitle: subtitle, compTitle: title };

  if (isEEActive) {
    return (
      <>
        <h2 className="text-xl my-24">Desktop</h2>
        <Image field={image} />
        <RichText field={title} />
        <RichText field={subtitle} />
        <h2 className="text-xl my-24">Mobile</h2>
        <Image field={mobileImage} />
        <RichText field={mobileTitle} />
        <RichText field={mobileSubtitle} />
        <h2 className="text-xl my-24">Call To Action</h2>
        <Link href={link?.value?.href || ''} {...link}>
          {link?.value?.text}
        </Link>
      </>
    );
  }

  return (
    <>
      <section
        className={`customStyle-Hero relative overflow-hidden ${isDark ? 'bg-gray' : 'bg-blue'}`}
      >
        <picture>
          <source srcSet={mobileImage.value?.src} media={`(max-width: ${screens.md})`} />
          <Image field={image} alt="" className="min-h-full min-w-full object-cover" />
        </picture>
        <HeroComponent
          subtitle={compSubtitle}
          title={compTitle}
          {...{ ...rest, isDark, link }}
          slotContent={!isMobile && <HeroStockTicker {...stockTickerOptions} />}
        />
      </section>
      {isMobile && <HeroStockTicker {...stockTickerOptions} variant="Accordion" />}
    </>
  );
};

export { HeroComponent, HeroCta };
export default Hero;
