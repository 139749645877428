import React from 'react';
import { BackButton } from 'src/components/Common/Jurisdiction';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import SvgLoader from 'src/components/Common/SvgLoader';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import useMediaQuery from 'src/lib/useMediaQuery';
import { useTranslation } from 'src/lib/useTranslation';

const HeaderButton = ({
  children,
  icon,
  onClick = () => {},
  size = 16,
}: {
  children: React.ReactNode;
  icon: string;
  onClick?: () => void;
  size?: number;
}) => (
  <button
    className="flex items-center justify-center px-8 sm:px-16 py-16 flex-grow capitalize"
    onClick={onClick}
  >
    <SvgLoader name={icon} color="text-white" size={size} className="mr-4" />
    {children}
  </button>
);

const Header = () => {
  const { state, dispatch } = useNavContext();
  const { status } = useAppContext();
  const jurisdictionText = status.jurisdiction?.stateName;
  const { nextLanguage, translate, isPending } = useTranslation();
  const isLg = useMediaQuery('lg');

  const handleLanguageToggle = () => {
    translate().then(() => {
      dispatch('close');
    });
  };

  if (state.prev) {
    return (
      <BackButton onClick={() => dispatch('prev')} className="pt-12">
        {state.prev.parent?.value?.text || 'Main Menu'}
      </BackButton>
    );
  }

  // Only render the header on smaller breakpoints
  if (isLg || typeof isLg === 'undefined') {
    return null;
  }

  return (
    <header className="text-white bg-blue w-full justify-start flex divide-x">
      <HeaderButton icon={isPending ? 'LoadingCircle' : 'Globe'} onClick={handleLanguageToggle}>
        {nextLanguage.text}
      </HeaderButton>
      <HeaderButton
        icon="Location"
        onClick={() => dispatch('open', { panel: 'left', component: 'Jurisdiction' })}
      >
        {jurisdictionText || 'Select Location'}
        <SvgLoader
          name="Arrow"
          color="text-white"
          size={12}
          className="ml-6"
          aria-hidden={true}
          focusable={false}
        />
      </HeaderButton>
    </header>
  );
};

export default Header;
