import Composition from 'src/lib/Composition';
import { getModal, stripQueryStringsFromSrc } from 'src/lib/helpers';
import Hero from './index';

const { compositionFunction, component } = Composition(Hero)(({ fields }) => {
  const slide = fields?.items[0];
  const fontColor = slide?.fields['Font Color']?.fields?.Setting?.value;
  const buttonColor = slide?.fields?.ButtonColor?.fields?.Setting?.value;
  const image = slide?.fields?.Image;
  const link = slide?.fields['Call to Action'];
  const mobileImage = slide?.fields['Mobile Image'];
  const mobileSubtitle = slide?.fields['Mobile Subhead'];
  const mobileTitle = slide?.fields['Mobile Headline'];
  const subtitle = slide?.fields?.Subhead;
  const title = slide?.fields?.Headline;
  const modal = getModal(slide?.fields);

  return {
    analytics: {
      category: 'hero',
      label: title?.value || '',
      action: [link?.value?.text, link?.value?.href].filter(Boolean).join(' | '),
      guid: slide.id,
      event: 'event-click',
    },
    color: fontColor || '',
    buttonColor: buttonColor || '',
    ctaType: slide?.fields?.['Call To Action Type']?.fields?.Setting?.value?.split(' ').join(''),
    image,
    isCtaAButton: slide?.fields?.['Call to Action as button']?.value,
    link,
    mobileImage: mobileImage?.value?.src
      ? mobileImage
      : {
          value: {
            ...image?.value,
            height: '153',
            src: `${stripQueryStringsFromSrc(image.value?.src)}?h=153&la=en&w=400`,
            width: '400',
          },
        },
    mobileSubtitle: mobileSubtitle?.value ? mobileSubtitle : subtitle,
    mobileTitle: mobileTitle?.value ? mobileTitle : title,
    modal,
    stockTickerOptions: {
      image: {
        src: fields?.['Chart Image']?.value?.href,
        alt: 'chart',
        width: 254,
        height: 200,
      },
      investorsLink: fields?.['Investors Link'],
      showStock: fields?.['Show Stock']?.value,
    },
    subtitle,
    title,
  };
});

export { compositionFunction, component as default };
