import Composition from 'src/lib/Composition';
import { gradients } from '../JSSHero/composition';
import data from './data';
import TopTasks from './index';
import { TopTasksProps } from './types';

const { component, compositionFunction } = Composition(TopTasks)<typeof data>(({ fields }) => {
  const theme = fields?.['Top Task Theme']?.value;
  const gradientBgOverlay = theme && gradients[theme as keyof typeof gradients];
  return {
    title: fields?.['Main Title'],
    subtitle: fields?.['Sub Title'],
    gradientBgOverlay,
    titleColor: (fields?.Color?.value?.toLowerCase() as TopTasksProps['titleColor']) || 'white',
    image: fields?.['Default BG Image Path'],
    mobileImage: fields?.['Default BG Image Path - Mobile'],
    items: fields?.items?.slice(0, 5).map(item => {
      return {
        icon: item.fields.Icon,
        title: item.fields.Title,
        link: item.fields.URL,
        analytics: {
          category: 'top_tasks',
          label: fields?.['Main Title'].value,
          action: [item.fields.Title.value, item.fields.URL.value.href].filter(Boolean).join(' | '),
          guid: item.id || '',
          event: 'event-click',
        },
      };
    }),
  };
});

export { compositionFunction, component as default };
