import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Button from 'src/components/Common/@electron/Button';
import Link from 'src/components/Common/Link';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import useMediaQuery from 'src/lib/useMediaQuery';
import { useTranslation } from 'src/lib/useTranslation';
import { PrimayNavProps } from '../types';

const Hamburger = ({ hamburgerAriaLabel }: Pick<PrimayNavProps, 'hamburgerAriaLabel'>) => {
  const isMobile = !useMediaQuery('lg');
  const { dispatch } = useNavContext();

  return (
    <div className="flex content-center">
      <button
        aria-label={hamburgerAriaLabel}
        aria-controls="hamburger-nav"
        aria-haspopup={true}
        className="p-12 -m-12 text-teal-darker"
        onClick={() => dispatch('open', { panel: 'left', component: 'NavTree' })}
      >
        <SvgLoader
          name="Hamburger"
          size={isMobile ? 20 : 24}
          className="flex-shrink-0 fill-current w-20 lg:w-24"
          focusable="false"
        />
      </button>
    </div>
  );
};

const NavTitle = ({
  href,
  text,
  guid,
  isAnchorPage = false,
}: PrimayNavProps['navTitle'] & { guid: string; isAnchorPage?: boolean }) => {
  const onClick = () => {
    track.component({
      event: 'event-click',
      category: 'top_level_navigation',
      action: href,
      label: text.value,
      guid,
    });
  };

  return (
    <div className="hidden sm:flex items-center sm:logo-de-sm lg:logo-de sm:pl-16 xl:pl-24 max-w-3xs text-xl text-blue border-l border-gray">
      {isAnchorPage ? (
        <label className="mt-4 line-clamp-1">{text.value}</label>
      ) : (
        <label className="mt-4 line-clamp-1">
          <Link onClick={onClick} href={href}>
            {text.value}
          </Link>
        </label>
      )}
    </div>
  );
};

const JurisdictionButton = ({
  children,
  guid,
  juris,
  pathname,
}: React.PropsWithChildren<{
  guid: string;
  pathname: string;
  juris: PrimayNavProps['juris'];
}>) => {
  const { dispatch } = useNavContext();

  const handleClick = () => {
    dispatch('open', { panel: 'right', component: 'Jurisdiction' });
    track.component({
      action: pathname,
      category: 'top_level_navigation',
      label: juris?.stateName || 'Select Location',
      guid,
    });
  };

  return (
    <li>
      <Button
        aria-haspopup={true}
        className="flex items-center py-6 lg:py-8 text-sm whitespace-nowrap text-teal-darker no-underline hover:underline"
        custom={true}
        onClick={handleClick}
      >
        <SvgLoader
          className="flex-shrink-0 fill-current mr-6 h-16"
          name="Location"
          viewBox="15 0 15 76"
          aria-hidden="true"
          focusable="false"
        />
        <span className="mt-2 capitalize">{children}</span>
      </Button>
    </li>
  );
};

const SpanishToggle = ({ guid, pathname }: { guid: string; pathname: string }) => {
  const { isPending, nextLanguage, translate } = useTranslation();

  const handleClick = () => {
    translate();
    track.component({
      action: pathname,
      category: 'top_level_navigation',
      label: nextLanguage.text,
      guid,
    });
  };

  return (
    <li className="js-spanish-toggle-btn">
      <Button
        className="flex items-center py-6 lg:py-8 text-sm whitespace-nowrap text-teal-darker no-underline hover:underline"
        custom={true}
        onClick={handleClick}
        lang={nextLanguage.code}
      >
        <SvgLoader
          className="flex-shrink-0 fill-current mr-6 h-16"
          name={isPending ? 'LoadingCircle' : 'Globe'}
          aria-hidden="true"
          focusable="false"
        />
        <span className="mt-2 capitalize">{nextLanguage.text}</span>
      </Button>
    </li>
  );
};

// Displays when authenticated and multiple experiences
const SwitchPortal = ({ href, text }: PrimayNavProps['switchPortal']) => (
  <li className="js-switch-btn">
    <Button className="hidden" custom={true} href={href}>
      <SvgLoader className="flex-shrink-0 fill-current mr-6 h-16" name="AppMenu" />
      <span className="mt-2 capitalize">
        <Text field={text} />
      </span>
    </Button>
  </li>
);

export { Hamburger, JurisdictionButton, NavTitle, SpanishToggle, SwitchPortal };
