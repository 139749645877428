import Composition from 'src/lib/Composition';
import { compositionFunction as callToActionComposition } from '../CallToAction/composition';
import data from './data';
import JSSTabs from './index';

const { component, compositionFunction } = Composition(JSSTabs)<
  typeof data & { rendering?: typeof data }
>(props => {
  // 'rendering' is created by sitecore-jss. it is the full layout response object that defines the component.
  // we spread 'props' in bc we need to create rendering outselves for non-sitecore use (storybook, tests).
  return { ...callToActionComposition(props), rendering: { ...props, ...props.fields.items } };
});

export { compositionFunction, component as default };
