import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import withErrorBoundary from 'src/lib/ErrorBoundary/withErrorBoundary';
import Logger from '../Logger';
import sitecoreMap from './sitecoreMap';
// Slightly better than just saying any...
// We know there will probably be a property called 'fields' but it could contain anything.
type JssObject = { [Key in string]?: any };
type JssLayout = {
  fields?: JssObject;
  params?: JssObject;
  placeholders?: JssObject;
  props?: any;
  id?: string;
  rendering?: Pick<JssLayout, 'fields'> & {
    componentName?: string;
    dataSource?: string;
    uid?: string;
  };
} & Record<string, any>;

/** Transforms props from Sitecore JSS layout response */
const Composition =
  <Props extends {} = any>(Component: (props: Props) => JSX.Element | null) =>
  <Fields extends JssLayout>(compositionFunction: (props: Fields) => Props) => ({
    compositionFunction,
    /** Using a HOC catches errors in the composition.tsx and index.tsx */
    component: withErrorBoundary(function CompositionComponent(jssProps: Fields) {
      // Do not return `null` in ExperienceEditor because the content author needs access to finish configuring the component content
      const isNotExperienceEditor = !isEditorActive();
      const hasNoFields = !jssProps.fields;

      if (isNotExperienceEditor && hasNoFields) {
        const componentName = jssProps.rendering?.componentName;
        const uid = jssProps.rendering?.uid;

        // We just want to warn on this issue since it is not technically an "error" (its just a bit sloppy content management bc really
        // there is no reason outside Experience Editor to have a component on the page without the datasource fields configured).
        Logger.warn(
          `No fields returned for ${componentName} (uid: ${uid}). Please ensure JSS component dataSource is set.`,
          { componentName }
        );

        return null;
      }

      const newProps = compositionFunction(jssProps);
      return <Component {...newProps} />;
    }),
  });

export { Composition as default, sitecoreMap };
export type { JssLayout, JssObject };
