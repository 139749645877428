import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import logo from 'src/assets/svgs/duke-logo.svg';
import Button from 'src/components/Common/@electron/Button';
import { DukeTagline } from 'src/components/Common/Svgs';
import track from 'src/lib/Analytics';
import GoogleTagManager from 'src/lib/GoogleTagManager';
import { MagnifyingGlass } from './MagnifyingGlass';
// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const footerProps = {
  copyright: { value: '© Duke Energy Corporation. All Rights Reserved.' },
  bottomLinks: [
    {
      href: '/legal/privacy',
      text: 'Privacy',
    },

    {
      href: '/legal/terms-of-use',
      text: 'Terms of Use',
    },

    {
      href: '/customer-service/accessibility',
      text: 'Accessibility',
    },
  ],
};

const Tagline = () => (
  <div className="px-16 md:px-24 py-24 md:py-32 border-b border-teal-darker">
    <div className="container-4xl">
      <DukeTagline
        className="mx-auto h-16 w-auto text-white fill-current"
        aria-label="Building a Smarter Energy Future"
      />
    </div>
  </div>
);

const BottomLinks = () => (
  <nav aria-label="Legal" className="lg:flex lg:justify-end lg:flex-grow py-12 lg:py-0">
    <ul className="lg:flex lg:space-x-24">
      {footerProps.bottomLinks.map(({ text, ...link }, index) => (
        <li key={index}>
          <a
            className="block lg:inline-block px-16 py-12 lg:p-0 no-underline hover:underline"
            {...link}
          >
            {text}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

const Copyright = () => (
  <p className="px-16 py-24 lg:p-0 border-t border-teal-darker lg:border-t-0">
    {footerProps.copyright.value}
  </p>
);

const Footer = () => (
  <footer>
    <div className="bg-blue text-white">
      <Tagline />
      <div className="lg:px-24 lg:py-24 text-center text-sm lg:text-xs">
        <div className="container-4xl lg:flex lg:flex-row-reverse lg:justify-center">
          <BottomLinks />
          <Copyright />
        </div>
      </div>
    </div>
  </footer>
);

const Header = () => (
  <header className="z-header sticky md:relative top-0">
    <div className="js-site-header-main px-16 md:px-24 py-12 sm:py-16 xl:py-24 bg-white border-b border-gray">
      <div className="container-full flex justify-center items-center h-full">
        <div className="flex items-center gap-16 xl:gap-24 z-10">
          <a href="/home" className="flex items-center">
            <img className="logo-de-sm lg:logo-de" src={logo} width={250} height={110} alt="" />
          </a>
        </div>
      </div>
    </div>
  </header>
);

const pageTitle = 'Page not found';

const NotFound = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const message = 'We can’t find the page you’re looking for.';
    const prevPage = (window as any).prevPage ?? '';

    track.component({
      category: 'error_404',
      label: prevPage,
      action: `${message} | ${pathname}`,
      event: 'event-load',
    });
  }, []);

  return (
    <div className="flex-grow flex flex-col">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={pageTitle} />
      </Helmet>
      <a
        className="btn btn-secondary fixed left-1/2 top-0 text-teal-darker bg-white transform -translate-x-1/2 -translate-y-full focus:translate-y-16 focus:z-modal"
        href="#main-content"
      >
        Skip to Content
      </a>
      <Header />
      <main id="main-content" className="flex-grow flex flex-col">
        <section className="flex flex-col flex-grow relative h-full overflow-hidden bg-teal-darker">
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-blue-dark opacity-40" />
          <div className="relative flex flex-col flex-grow justify-center items-center container-3xl px-24 py-32 text-white text-center">
            <MagnifyingGlass />
            <h1 className="mt-12 md:mt-32 text-xl-fixed md:text-2xl-fixed">
              We can’t find the page you’re <span className="whitespace-nowrap">looking for.</span>
            </h1>
            <p className="mt-12 md:mt-32 text-md md:text-lg">
              We’ve searched high and low for your page, but it{' '}
              <span className="whitespace-nowrap">can’t be found.</span>
            </p>
            <p className="mt-16 md:mt-32">
              <Button variant="secondary-reversed" href="/home">
                Go to home page
              </Button>
            </p>
          </div>
        </section>
        <GoogleTagManager />
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
