import Composition from 'src/lib/Composition';
import RichText from './index';

const RichTextWrapped = (props: Parameters<typeof RichText>[0]) => {
  // If it has a parent then we let the parent administer the padding,
  // otherwise it is a standalone component and needs to handle its own padding
  return (
    <section className={`overflow-auto md:container-3xl ${props.parent ? '' : 'p-16 xl:p-24'}`}>
      <RichText {...props} />
    </section>
  );
};

const { component } = Composition(RichTextWrapped)(props => {
  // props.parent is an arbitrary prop manually added to the Placeholder e.g. `<Placeholder parent="MultiColumn">`
  // This lets us know the RichText is being used inside another component like MultiColumn
  return { field: props.fields?.Content, guid: props.rendering?.dataSource, parent: props.parent };
});

export default component;
