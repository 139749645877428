import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useEffect, useRef, useState } from 'react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import useIntersection from 'src/lib/useIntersection';
import useMediaQuery from 'src/lib/useMediaQuery';
import IndicatorDots from './components';
import styleOptions from './styleOptions';
import { CardContainerProps, ProductCardType, ProductItemsType } from './types';

const ProductCards = ({ items, ...rest }: ProductCardType) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const autoScrollRef = useRef<HTMLUListElement | null>(null);
  const cardTotal = items.length;
  if (!items.length) return null;
  const featuredCards = items.filter(item => item.isFeatured);
  const featuredCardsTotal = featuredCards.length;
  const regularCardsTotal = cardTotal - featuredCardsTotal;
  // only show regular cards if there are at least 2
  if (featuredCardsTotal === 0 && regularCardsTotal < 2) return null;
  // Featured Card alone: not in a list, different layout at the 'md' breakpoint, no nav/breadcrumb dot below
  if (featuredCardsTotal === 1 && regularCardsTotal < 2) {
    return (
      <CallToAction isCtaAButton={false} {...rest}>
        <div id="featured-product-card" data-testid="solo-card-feature" className="container-4xl">
          <div className="relative flex flex-col sm:flex-row mx-auto max-w-xs sm:max-w-none w-full h-full rounded-md shadow sm:hover:shadow-md bg-white transition-all duration-300 sm:will-change-transform transform sm:hover:-translate-y-4">
            <CardContent {...featuredCards[0]} cardType={'featuredAlone'} />
          </div>
        </div>
      </CallToAction>
    );
  }

  return (
    <CallToAction isCtaAButton={false} {...rest}>
      <div id="product-cards" className="container-4xl">
        <ul
          ref={autoScrollRef}
          className="snap md:snap-none scrollbar-none flex md:flex-wrap md:justify-center px-16 py-24 md:p-0 -mx-16 -my-24 md:-m-8 lg:-m-12 overflow-x-auto md:overflow-x-visible"
        >
          {/* Empty presentational list item to force the first card to center during scroll-snap on small devices */}
          <li className="flex-shrink-0 w-11/12 sm:w-7/12 md:hidden" role="presentation"></li>
          {items.map((item, index) => {
            // max of 4 items
            if (index < 4) {
              return (
                <CardContainer item={item} key={index} handleScroll={() => setActiveIndex(index)} />
              );
            }
            return null;
          })}
          <li className="flex-shrink-0 w-11/12 sm:w-7/12 md:hidden" role="presentation"></li>
        </ul>
        <IndicatorDots total={cardTotal} {...{ activeIndex, autoScrollRef }} />
      </div>
    </CallToAction>
  );
};

// only for grouped product cards (not featured alone)
const CardContainer = ({ item, handleScroll }: CardContainerProps) => {
  const isMobile = !useMediaQuery('md');
  const { isIntersecting, ref } = useIntersection<HTMLDivElement>({ threshold: 0.6 }, false);
  const isZoomed = isMobile && isIntersecting;
  useEffect(() => {
    isIntersecting && handleScroll();
  }, [isIntersecting]);
  return (
    <li
      className={`snap-center flex-shrink-0 sm:px-4 md:p-8 lg:p-12 w-11/12 sm:w-7/12 max-w-xs md:max-w-none ${
        item.isFeatured ? 'md:w-full' : 'md:w-1/3'
      }`}
      data-testid={item.isFeatured ? 'card-feature' : 'card-regular'}
    >
      <div
        className={`relative flex flex-col w-full h-full rounded-md shadow md:hover:shadow-md bg-white transition-all duration-300 md:will-change-transform transform md:hover:-translate-y-4 scale-90 md:scale-100 ${
          item.isFeatured ? 'md:flex-row' : ''
        } ${isZoomed ? 'scale-100 shadow-lg' : ''}`}
        ref={ref}
      >
        <CardContent {...item} cardType={item.isFeatured ? 'featured' : 'regular'} />
      </div>
    </li>
  );
};

const CardContent = ({
  analytics,
  body,
  category,
  cta,
  image,
  isFeatured,
  title,
  cardType = 'regular',
}: ProductItemsType) => {
  const TitleTag = isFeatured ? 'h3' : 'p';
  const styles = styleOptions[cardType];

  return (
    <>
      <LazyImage
        className={`${styles.imgClasses} object-cover aspect-16/9 w-full h-auto rounded-t-md bg-gray-light`}
        srcSet={styles.imgSrcSet as any}
        sizes={styles.imgSizes}
        alt=""
        {...image}
      />
      <div className={`flex flex-col flex-1 p-24 ${styles.textContainer}`}>
        <Text className="text-xs font-bold uppercase" field={category} tag="span" />
        <TitleTag className={`flex-1 mt-8 text-xl text-gray-dark ${styles.title}`}>
          <Link {...cta} className="link-block" onClick={() => track.component(analytics)}>
            <Text field={title} />
          </Link>
        </TitleTag>
        {isFeatured && <Text className={`mt-8 ${styles.body}`} field={body} tag="p" />}
        <p className="mt-16" aria-hidden="true">
          <span className={`btn btn-static btn-full ${styles.button}`}>
            {cta.value.text || 'Learn More'}
          </span>
        </p>
      </div>
    </>
  );
};

export default ProductCards;
