import DisabledComponentFormsMessage from 'src/components/Common/DisabledFormsMessage';
import { useAppContext } from '../Contexts/AppContext';

export type DisabledComponentsType = keyof ReturnType<typeof useToggle>;

const useToggle = () => {
  const { status } = useAppContext();
  const { itemPath } = status;

  // Do NOT disable Backdoor SignIn
  const isBackdoorSignIn = itemPath === '/info/unindexed/backdoor-sign-in';

  const values = status.route?.placeholders['jss-public-header'].find(
    x => x.componentName === 'Global Toggle'
  )?.fields;

  return {
    signIn: values?.signIn?.toggleSignIn && !isBackdoorSignIn,
    stopService: values?.stopService?.toggleStopService,
    payAsGuest: values?.payAsGuest?.togglePayAsAGuest,
  };
};

export const withGlobalToggle = <Props extends {}>(
  WrappedComp: React.ComponentType<Props>,
  name: DisabledComponentsType
) => {
  const Component = (props: Props) => {
    const disabledList = useToggle();

    const isToggledOff = disabledList[name];

    if (isToggledOff) {
      return <DisabledComponentFormsMessage componentName={name} />;
    }

    return <WrappedComp {...props} />;
  };

  return Component;
};
