/* eslint-disable no-console */
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'src/lib/AppInsights';

/** retrieves acoustic tealeaf session id */
const getTLTSID = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return (window as any)?.TLT?.getTLTSessionCookieInfo?.()?.tltCookieValue;
};

type LoggerParams = [
  error: Error | string | unknown,
  options?: { message?: string; errorId?: string; componentName?: string }
];

const createLogger =
  (severityLevel: SeverityLevel) => (error: LoggerParams[0], options?: LoggerParams[1]) => {
    if (error instanceof Error && error?.name === 'AbortError') {
      return null;
    }

    if (process.env.NODE_ENV === 'test') {
      return null;
    }

    if (process.env.NODE_ENV === 'development') {
      return console.error(error);
    }

    return appInsights.trackException({
      // The default type for a try/catch error in typescript is `unknown`
      // (https://devblogs.microsoft.com/typescript/announcing-typescript-4-4/#using-unknown-in-catch-variables).
      // We add a exhaustive check for the exception property so we can support accepting this `unknown` type.
      exception:
        // if it's already an Error just send it
        // if it's a string, make a new error with that string
        // if it's something else, create a generic Error('error')
        error instanceof Error
          ? error
          : typeof error === 'string'
          ? new Error(error)
          : new Error('error'),
      severityLevel,
      properties: {
        componentName: options?.componentName,
        errorId: options?.errorId,
        message: options?.message,
        tealeafSessionId: getTLTSID(),
      },
    });
  };

/** Sends an AI log */
const Logger = (() => {
  const main = (...args: LoggerParams) => createLogger(SeverityLevel.Error)(...args);
  /** Sends an AI log with "error" severity level */
  main.error = main;
  /** Sends an AI log with "warning" severity level */
  main.warn = createLogger(SeverityLevel.Warning);

  return main;
})();

export default Logger;
