import { useEffect } from 'react';
import { Header } from 'src/components/Common/Jurisdiction';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { JurisdictionCodes, StepProps } from 'src/components/Common/Jurisdiction/types';
import { LoadingDots } from 'src/components/Common/LoadingOverlay';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import setJurisdictionCookie from 'src/lib/setJurisdictionCookie';

const isString = (value: unknown): value is typeof JurisdictionCodes[number] =>
  JurisdictionCodes.some(item => item === value);

const Loading = ({ headline, id, subhead, ...rest }: StepProps) => {
  const appContext = useAppContext();
  const navContext = useNavContext();
  const { set, whichState } = useStep();

  const handleAnalytics = () => {
    track.component({
      action: whichState?.name.split('-').join('|') || '',
      category: 'jurisdiction_change',
      guid: id,
      label: headline?.value,
    });
  };

  useEffect(() => {
    const code = whichState?.code;

    if (isString(code)) {
      const doFetch = async () => {
        await setJurisdictionCookie(code);
        await appContext.mutate();
        handleAnalytics();
        navContext.dispatch('close');
      };
      doFetch();
    } else {
      set('Error');
    }
  }, []);

  return (
    <>
      <Header
        {...rest}
        headline={headline}
        subhead={{
          ...subhead,
          value: (
            <>
              {subhead?.value} <b>{whichState?.name}</b>
            </>
          ) as unknown as string,
        }}
      />
      <div className="flex justify-center pt-32 mx-16">
        <LoadingDots />
      </div>
    </>
  );
};

export default Loading;
