import Composition from 'src/lib/Composition';
import data from './data';
import Jurisdiction from './index';
import { JurisdictionProps } from './types';

type compType = typeof data & Pick<JurisdictionProps, 'initialDirection'>;

const { component, compositionFunction } = Composition(Jurisdiction)<compType>(props => {
  const initialDirection = props?.initialDirection;
  const fields = props?.fields;
  return {
    initialDirection,
    useZipText: fields?.['Use my ZIP Text']?.value || 'Use my ZIP code to set my location',
    backToStatesText: fields?.['Back Text']?.value || 'Back to States',
    steps: fields?.steps?.map(({ fields, id }) => ({ ...fields, name: fields.name?.value, id })),
    states: fields?.states?.map(state => ({
      name: state.fields?.name?.value,
      code: Array.isArray(state.fields?.code)
        ? state.fields.code.map(({ fields }) => ({
            code: fields.code?.value,
            name: fields.name?.value,
            image: fields.image?.value,
          }))
        : (state.fields?.code as any).value,
    })),
  };
});

export { compositionFunction, component as default };
