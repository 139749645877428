import track from 'src/lib/Analytics';
import { toKebabCase } from 'src/lib/helpers';

const CecAuthData = {
  dat: {
    SegmentId: '1',
    ssaUserRole: 'Internal_User',
  },
  username: 'joyvc',
  token_type: 'Bearer',
  client_id: 'DE.AUTH-CDP-joyvc',
  access_token: 'test_token',
  expires_in: 3599,
};

const CECErrorData = {
  Status: 'Error',
  MessageText: "Data is not enough to make web service call: GetPortalToken. CdpId: ''",
  ReturnCode: 370027023,
};

export type CECAuthDataType = typeof CecAuthData;
export type CECErrorType = typeof CECErrorData;
export type CECResponseType = CECAuthDataType | CECErrorType;

export const getCECAuthToken = async (segmentId: number) => {
  const endpoint = `/facade/api/Authentication/GetPortalToken?segmentId=${segmentId}`;
  let data;
  try {
    const response = await fetch(endpoint);
    data = await response.json();
  } catch (err) {
    throw new Error('E');
  }
  return data as CECResponseType;
};

export const sendCECErrorAnalytics = (errMessage: string) => {
  const message = toKebabCase(errMessage || 'E')
    .replace(/,/g, '')
    .replace(/:/g, '')
    .replace(/'/g, '')
    .replace(/\./g, '');
  track({
    event: 'send-VPV',
    'vpv-name': `/vpv/de/page/clean-energy-connection/error/clean-energy-connection/na/na/${message}`,
  });
  track.component({
    category: 'de_cec_error',
    action: message,
    label: '/my-account/dashboard',
    event: 'event-click',
  });
};
