import { Image } from '@sitecore-jss/sitecore-jss-react';
import Link from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Contexts/AppContext';
import { progressJurisdictions } from 'src/lib/constants';
import { PrimayNavProps } from '../types';

const isSubbrand = (currentJuris?: string) => {
  const subbrandJurisdictions = progressJurisdictions;
  return subbrandJurisdictions.some(subbrand => subbrand === currentJuris);
};

export const DefaultLogo = ({ analytics, link, image }: PrimayNavProps['logo']) => {
  return (
    <Link
      aria-label="Duke Energy homepage"
      className="flex items-center"
      onClick={() => track.component(analytics)}
      {...link}
    >
      <Image field={image} className="logo-de-sm lg:logo-de" alt="" />
    </Link>
  );
};

export const Logo = ({ analytics, link, image }: PrimayNavProps['logo']) => {
  const context = useAppContext();
  const currentJuris = context.status.jurisdiction?.jurisdictionCode;

  const options = {
    default: 'logo-de-sm lg:logo-de',
    subbrand: 'logo-subbrand-sm lg:logo-subbrand',
  };

  return (
    <Link
      aria-label="Duke Energy homepage"
      className="flex items-center"
      onClick={() => track.component(analytics)}
      {...link}
    >
      <Image
        field={image}
        className={isSubbrand(currentJuris) ? options.subbrand : options.default}
        alt=""
      />
    </Link>
  );
};
