import React, { useMemo } from 'react';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import LazyImage from 'src/components/Common/LazyImage';
import Link from 'src/components/Common/Link';
import RichText from 'src/components/Common/RichText_Old';
import track from 'src/lib/Analytics';
import { pxPush } from 'src/lib/Analytics/px';
import useMediaQuery from 'src/lib/useMediaQuery';
import { CardProps } from '../types';
import { Title } from './components/title';
import { displayBehavior } from './displayBehavior';
import { WrapperProps } from './types';

const Wrapper = ({ children, handleClick, image, link, mobileLink, variantData }: WrapperProps) => {
  const isMobileViewport = !useMediaQuery('md');
  const computedLink = isMobileViewport ? mobileLink : link;
  const href =
    computedLink.value?.linktype === 'javascript'
      ? computedLink?.value?.url
      : computedLink?.value?.href;

  const ConditionalLink = href ? (
    <Link
      {...computedLink}
      href={href}
      aria-hidden="true"
      className="block h-fit mr-12 sm:mr-0 sm:mb-12 flex-shrink-0"
      onClick={handleClick}
      tabIndex={-1}
    >
      <LazyImage
        alt=""
        className={variantData.imgClasses}
        sizes={variantData.imgSizes}
        srcSet={[{ w: 120 }, { w: 240 }, { w: 280 }, { w: 560 }]}
        {...image}
      />
    </Link>
  ) : (
    <div className="block h-fit mr-12 sm:mr-0 sm:mb-12 flex-shrink-0">
      <LazyImage
        alt=""
        className={variantData.imgClasses}
        sizes={variantData.imgSizes}
        srcSet={[{ w: 120 }, { w: 240 }, { w: 280 }, { w: 560 }]}
        {...image}
      />
    </div>
  );

  return (
    <li className={`p-12 sm:p-16 sm:w-1/2 md:w-1/3 lg:w-1/4 ${variantData.item}`}>
      <div className="relative flex sm:flex-col sm:h-full">
        {image?.value?.src && ConditionalLink}
        {children}
      </div>
    </li>
  );
};

const Card = ({
  analytics,
  index,
  length,
  link,
  mobileLink,
  modal,
  text,
  title,
  ctaHasTitle = true,
  variant = 'rectangular',
  ...rest
}: CardProps & { ctaHasTitle?: boolean }) => {
  const variantStyles = useMemo(() => displayBehavior(length, index), [length, index]);
  const variantData = variantStyles[variant];
  const { dispatch } = useBodyContext();
  const handleClick = (event: React.MouseEvent) => {
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }
    track.component(analytics);
    pxPush({ eventName: analytics.category, clickText: title?.value });
  };

  // some navcards don't have a title (and don't actually navigate)... like at /safety-and-preparedness/storm-safety/after-the-storm
  const hasTitle = title?.value && title.value.trim().length > 0;
  // a11y: not using header tag if no <p> content in card. then checking if there is a CTA h2 before using h3s to keep headers in order
  const HeaderTag = !text?.value ? 'p' : ctaHasTitle ? 'h3' : 'h2';

  return (
    <Wrapper {...{ ...rest, handleClick, link, mobileLink, variant, variantData }}>
      <div
        className={`flex flex-col flex-grow justify-center sm:justify-start text-left ${
          variant !== 'rectangular' ? 'sm:text-center' : ''
        }`}
      >
        {hasTitle && (
          <Title
            {...{
              analytics,
              HeaderTag,
              index,
              link,
              mobileLink,
              modal,
              title,
              ...rest,
              handleClick,
              variant,
              variantData,
            }}
          />
        )}
        {text && <RichText field={text} tag="p" className="mt-4 md:mt-6 line-clamp-6" />}
      </div>
    </Wrapper>
  );
};

export default Card;
